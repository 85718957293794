import locale from "localization";
import {
  BusinessType,
  FleetAccountType,
  PLBTypes,
  InvoicingSetUp,
  IntroductoryPromoOffer,
} from "enums";
import Validation from "utils/validation.utils";
import { prettifyFleetAccountType } from "utils";
import {
  prettifyPlbType,
  prettifyInvoicingSetUp,
  prettifyIntroductoryPromoOffer,
} from "utils/pretty.utils";

export const initialState = (state = {}) => {
  const {
    businessName = {
      value: "",
    },
    businessNature = {
      value: "",
    },
    businessType = {
      value: "none",
    },
    accountType = {
      value: "",
    },
    businessAddress = {
      value: "",
    },
    province = {},
    city = {},
    emailAddresses = {
      value: [],
    },
    referenceStationId = {
      value: "",
    },
    referenceStationType = {
      value: "",
    },
    priceAssessmentDate = {
      value: "",
    },
    dateTimeOnboarded = {
      value: "",
    },
    fleetStations = {
      value: [],
      isAll: false,
    },
    fuelCards = {
      value: [],
      isAll: false,
    },
    depotId = {
      value: "",
    },
    shortName = {
      value: "",
    },
    acquirerName = {
      value: "",
    },
    acquirerEmail = {
      value: "",
    },
    introductoryPromoOffer = {
      value: "",
    },
    invoicingSetup = {
      value: "",
    },
    plbType = {
      value: "",
    },
    businessTelephoneNumber = {
      value: "",
    },
    businessRepresentativeName = {
      value: "",
    },
    representativeMobileNumber = {
      value: "",
    },
    representativeEmail = {
      value: "",
    },
    baseDieselPrice = {
      value: "",
    },
    baseGas91Price = {
      value: "",
    },
    baseGas95Price = {
      value: "",
    },
    baseGas97Price = {
      value: "",
    },
    discountDiesel = {
      value: "0",
    },
    discountGas91 = {
      value: "0",
    },
    discountGas95 = {
      value: "0",
    },
    discountGas97 = {
      value: "0",
    },
    accuMovementDiesel = {
      value: "",
    },
    accuMovementGas91 = {
      value: "",
    },
    accuMovementGas95 = {
      value: "",
    },
    accuMovementGas97 = {
      value: "",
    },
    contractPriceDiesel = {
      value: "",
    },
    contractPriceGas91 = {
      value: "",
    },
    contractPriceGas95 = {
      value: "",
    },
    contractPriceGas97 = {
      value: "",
    },
    estimatedDieselVolume = {
      value: "",
    },
    estimatedGas91Volume = {
      value: "",
    },
    estimatedGas95Volume = {
      value: "",
    },
    estimatedGas97Volume = {
      value: "",
    },
    existingDieselVolume = {
      placeholder: "0.000",
    },
    existingGas91Volume = {
      placeholder: "0.000",
    },
    existingGas95Volume = {
      placeholder: "0.000",
    },
    existingGas97Volume = {
      placeholder: "0.000",
    },
    sdumDiesel = {
      value: "",
    },
    sdumGas91 = {
      value: "",
    },
    sdumGas95 = {
      value: "",
    },
    sdumGas97 = {
      value: "",
    },
    freightDiesel = {
      value: "",
    },
    freightGas91 = {
      value: "",
    },
    freightGas95 = {
      value: "",
    },
    freightGas97 = {
      value: "",
    },
    status = {
      value: "",
    },
    stationType = {
      value: "",
    },
  } = state;

  return {
    businessName: {
      name: "businessName",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.businessName,
      horizontal: true,
      ...businessName,
    },
    businessNature: {
      name: "businessNature",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.natureOfBusiness,
      horizontal: true,
      ...businessNature,
    },
    businessType: {
      name: "businessType",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      placeholder: locale.searchOrSelect,
      label: locale.typesOfBusiness,
      horizontal: true,
      options: [
        {
          label: BusinessType.Corporation,
          value: BusinessType.Corporation,
        },
        {
          label: BusinessType.Partnership,
          value: BusinessType.Partnership,
        },
        {
          label: BusinessType.SoleProprietorship,
          value: BusinessType.SoleProprietorship,
        },
      ],
      ...businessType,
    },
    accountType: {
      name: "accountType",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.accountType,
      horizontal: true,
      options: [
        {
          label: prettifyFleetAccountType(FleetAccountType.FleetAccount),
          value: FleetAccountType.FleetAccount,
        },
        {
          label: prettifyFleetAccountType(FleetAccountType.KeyAccount),
          value: FleetAccountType.KeyAccount,
        },
      ],
      ...accountType,
    },
    businessAddress: {
      name: "businessAddress",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.businessAddress,
      horizontal: true,
      ...businessAddress,
    },
    province: {
      name: "province",
      required: true,
      placeholder: locale.searchOrSelect,
      label: locale.province,
      horizontal: true,
      ...province,
    },
    city: {
      name: "city",
      required: true,
      placeholder: locale.searchOrSelect,
      label: locale.city,
      horizontal: true,
      ...city,
    },
    emailAddresses: {
      name: "emailAddresses",
      validations: [Validation.required({ noMessage: true })],
      required: true,
      label: locale.emailForFleetAccount,
      // helperText: locale.emailForFleetAccountHelperText,
      horizontal: true,
      ...emailAddresses,
    },
    referenceStationId: {
      name: "referenceStationId",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      placeholder: locale.searchOrSelect,
      inputLabel: locale.referenceStation,
      horizontal: true,
      disableAllOption: true,
      ...referenceStationId,
    },
    priceAssessmentDate: {
      name: "priceAssessmentDate",
      label: locale.priceAssessmentDate,
      horizontal: true,
      ...priceAssessmentDate,
    },
    dateTimeOnboarded: {
      ...dateTimeOnboarded,
    },
    referenceStationType: {
      name: "referenceStationType",
      label: locale.refSStationType,
      horizontal: true,
      ...referenceStationType,
    },
    fleetStations: {
      name: "fleetStations",
      validations: [
        ({ value, isAll }) => {
          if (isAll) {
            return {};
          }
          // if (!isAll && !value.length) {
          //   return {
          //     error: true,
          //     message: "",
          //   };
          // }
          return {};
        },
      ],
      placeholder: locale.searchOrSelect,
      inputLabel: locale.redemptionStations,
      horizontal: true,
      disableAllOption: true,
      isAll: false,
      ...fleetStations,
    },
    fuelCards: {
      name: "fuelCards",
      validations: [
        ({ value, isAll }) => {
          if (isAll) {
            return {};
          }
          return {};
        },
      ],
      horizontal: true,
      disableAllOption: true,
      isAll: false,
      ...fuelCards,
    },
    depotId: {
      name: "depotId",
      // required: true,
      // validations: [Validation.required({ noMessage: true })],
      placeholder: locale.searchOrSelect,
      inputLabel: locale.depot,
      horizontal: true,
      disableAllOption: true,
      ...depotId,
    },
    shortName: {
      name: "shortName",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumeric(),
        Validation.minlength(5),
      ],
      placeholder: locale.acquirerNameSample,
      label: locale.uniqueFuelCodePrefix,
      horizontal: true,
      ...shortName,
    },
    acquirerName: {
      name: "acquirerName",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.acquirerName,
      horizontal: true,
      ...acquirerName,
    },
    acquirerEmail: {
      name: "acquirerEmail",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.emailAddress({ noMessage: true }),
      ],
      label: locale.acquirerEmail,
      horizontal: true,
      ...acquirerEmail,
    },
    introductoryPromoOffer: {
      name: "introductoryPromoOffer",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.introductoryPromo,
      horizontal: true,
      options: [
        {
          label: prettifyIntroductoryPromoOffer(IntroductoryPromoOffer.Basic),
          value: IntroductoryPromoOffer.Basic,
        },
        {
          label: prettifyIntroductoryPromoOffer(IntroductoryPromoOffer.GlobalLinker),
          value: IntroductoryPromoOffer.GlobalLinker,
        },
        {
          label: prettifyIntroductoryPromoOffer(IntroductoryPromoOffer.Mias),
          value: IntroductoryPromoOffer.Mias,
        },
        {
          label: prettifyIntroductoryPromoOffer(IntroductoryPromoOffer.NoIntroductoryPromo),
          value: IntroductoryPromoOffer.NoIntroductoryPromo,
        },
        {
          label: prettifyIntroductoryPromoOffer(IntroductoryPromoOffer.IntroductoryPlbHedging),
          value: IntroductoryPromoOffer.IntroductoryPlbHedging,
        },
      ],
      ...introductoryPromoOffer,
    },
    invoicingSetup: {
      name: "invoicingSetup",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.invoicingSetup,
      horizontal: true,
      options: [
        {
          label: prettifyInvoicingSetUp(InvoicingSetUp.SINGLE_INVOICING),
          value: InvoicingSetUp.SINGLE_INVOICING,
        },
        {
          label: prettifyInvoicingSetUp(InvoicingSetUp.DEFAULT),
          value: InvoicingSetUp.DEFAULT,
        },
      ],
      ...invoicingSetup,
    },
    plbType: {
      name: "plbType",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.plbType,
      horizontal: true,
      options: [
        {
          label: prettifyPlbType(PLBTypes.PLB_HEDGING),
          value: PLBTypes.PLB_HEDGING,
        },
        {
          label: prettifyPlbType(PLBTypes.PLB_LOCQPAY),
          value: PLBTypes.PLB_LOCQPAY,
        },
        {
          label: prettifyPlbType(PLBTypes.PLB_LOCQPAY_MANUAL_PO),
          value: PLBTypes.PLB_LOCQPAY_MANUAL_PO,
        },
      ],
      ...plbType,
    },
    businessTelephoneNumber: {
      name: "businessTelephoneNumber",
      required: true,
      label: locale.businessTelephoneNumber,
      placeholder: locale.businessTelephoneNumberSample,
      horizontal: true,
      ...businessTelephoneNumber,
    },
    businessRepresentativeName: {
      name: "businessRepresentativeName",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.businessRepresentativeName,
      horizontal: true,
      ...businessRepresentativeName,
    },
    representativeMobileNumber: {
      name: "representativeMobileNumber",
      required: true,
      maxLength: 11,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.mobileNumber(),
      ],
      label: locale.representativeMobileNumber,
      placeholder: locale.representativeMobileNumberSample,
      horizontal: true,
      ...representativeMobileNumber,
    },
    representativeEmail: {
      name: "representativeEmail",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.emailAddress({ noMessage: true }),
      ],
      label: locale.representativeEmail,
      horizontal: true,
      ...representativeEmail,
    },
    baseDieselPrice: {
      name: "baseDieselPrice",
      // required: true,
      // validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...baseDieselPrice,
    },
    baseGas91Price: {
      name: "baseGas91Price",
      // required: true,
      // validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...baseGas91Price,
    },
    baseGas95Price: {
      name: "baseGas95Price",
      // required: true,
      // validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...baseGas95Price,
    },
    baseGas97Price: {
      name: "baseGas97Price",
      // required: true,
      // validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...baseGas97Price,
    },
    discountDiesel: {
      name: "discountDiesel",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...discountDiesel,
    },

    discountGas91: {
      name: "discountGas91",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...discountGas91,
    },
    discountGas95: {
      name: "discountGas95",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...discountGas95,
    },
    discountGas97: {
      name: "discountGas97",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...discountGas97,
    },
    accuMovementDiesel: {
      name: "accuMovementDiesel",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementDiesel,
    },
    accuMovementGas91: {
      name: "accuMovementGas91",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas91,
    },
    accuMovementGas95: {
      name: "accuMovementGas95",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas95,
    },
    accuMovementGas97: {
      name: "accuMovementGas97",
      innerLabel: true,
      parseNumber: true,
      ...accuMovementGas97,
    },
    contractPriceDiesel: {
      name: "contractPriceDiesel",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...contractPriceDiesel,
    },
    contractPriceGas91: {
      name: "contractPriceGas91",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas91,
    },
    contractPriceGas95: {
      name: "contractPriceGas95",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas95,
    },
    contractPriceGas97: {
      name: "contractPriceGas97",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...contractPriceGas97,
    },
    estimatedDieselVolume: {
      name: "estimatedDieselVolume",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...estimatedDieselVolume,
      placeholder: "0.000",
    },
    estimatedGas91Volume: {
      name: "estimatedGas91Volume",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas91Volume,
      placeholder: "0.000",
    },
    estimatedGas95Volume: {
      name: "estimatedGas95Volume",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas95Volume,
      placeholder: "0.000",
    },
    estimatedGas97Volume: {
      name: "estimatedGas97Volume",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...estimatedGas97Volume,
      placeholder: "0.000",
    },
    existingDieselVolume: {
      name: "existingDieselVolume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingDieselVolume,
      placeholder: "0.000",
    },
    existingGas91Volume: {
      name: "existingGas91Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas91Volume,
      placeholder: "0.000",
    },
    existingGas95Volume: {
      name: "existingGas95Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas95Volume,
      placeholder: "0.000",
    },
    existingGas97Volume: {
      name: "existingGas97Volume",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...existingGas97Volume,
      placeholder: "0.000",
    },
    sdumDiesel: {
      name: "sdumDiesel",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...sdumDiesel,
    },
    sdumGas91: {
      name: "sdumGas91",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...sdumGas91,
    },
    sdumGas95: {
      name: "sdumGas95",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...sdumGas95,
    },
    sdumGas97: {
      name: "sdumGas97",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...sdumGas97,
    },
    freightDiesel: {
      name: "freightDiesel",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...freightDiesel,
    },
    freightGas91: {
      name: "freightGas91",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...freightGas91,
    },
    freightGas95: {
      name: "freightGas95",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...freightGas95,
    },
    freightGas97: {
      name: "freightGas97",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...freightGas97,
    },
    status: {
      name: "status",
      ...status,
    },
    stationType: {
      name: "stationType",
      ...stationType,
    },
  };
};
