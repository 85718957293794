import { manualRedemption } from "apis";
import { Intro, BackLink, Button, Text } from "components/commons";
import { ConfirmModal } from "components/modals";
import { DateTime, Path } from "enums";
import { useApi, useForm, useModal, useRouter } from "hooks";
import moment from "moment";
import locale from "localization";
import { useCallback, useMemo } from "react";
import { formatDate, formatNumber } from "utils";
import ManualRedemptionForm from "./manual-redemption-form";

import { initialState as formState } from "./manual-redemption.state";

const ManualRedemptionModule = () => {
  const confirmModal = useModal();
  const successModal = useModal();

  const { history } = useRouter();

  const applyManualRedemption = useApi({
    api: manualRedemption,
  });

  const form = useMemo(() => {
    return formState();
  }, []);

  const {
    fields,
    modifyField,
    submitForm,
    modifyForm,
    isFormSubmittable,
    applyFieldErrors,
    makeFormInvalid,
    getFormValues,
  } = useForm({
    initialState: form,
  });

  const applyRedemption = useCallback(() => {
    confirmModal?.show({
      title: <Text style={{ color: "#000" }}>{locale?.proceedManualRedemption}</Text>,
      content: locale?.manualRedemptionQuestion,
      loading: applyManualRedemption?.loading,
      secondary: {
        text: locale?.cancel,
        onClick: () => confirmModal?.close(),
      },
      primary: {
        text: locale?.yesProceed,
        onClick: async () => {
          const resp = await applyManualRedemption?.request({
            userId: fields?.mobileAccountNumber?.userId,
            literVolume: parseFloat(formatNumber(fields?.liters?.value)),
            productCode: fields?.product?.value,
            redeemedAt: moment(
              `${formatDate(fields?.redemptionDate?.value, DateTime.K)} ${
                fields?.redemptionTime?.value
              }`
            ).toISOString(),
            remarks: fields?.remarks?.value,
            stationCode: fields?.redemptionStation?.value,
          });
          if (resp) {
            confirmModal?.close();
            successModal?.show({
              title: <Text style={{ color: "#000" }}>{locale?.exclamatedSuccess}</Text>,
              content: locale.manualRedemptionSuccess,
              primary: {
                onClick: () => {
                  history.push(Path.ConsumerRedemption);
                },
              },
            });
          }
        },
      },
    });
  }, [fields, confirmModal, applyManualRedemption, successModal, history]);

  const stateForm = {
    fields,
    modifyField,
    submitForm,
    modifyForm,
    applyFieldErrors,
    isFormSubmittable,
    makeFormInvalid,
    getFormValues,
  };

  return (
    <div>
      <ConfirmModal
        {...confirmModal}
        loading={applyManualRedemption?.loading}
        closable={!applyManualRedemption?.loading}
      />

      <ConfirmModal {...successModal} />
      <BackLink text={locale.fuelCodeRedemptions} path={Path.ConsumerRedemption} />
      <div
        style={{
          display: "flex",
        }}
      >
        <Intro
          style={{
            minWidth: "300px",
          }}
          title={locale.manualRedeem}
        />
      </div>
      <ManualRedemptionForm {...stateForm} />
      <Button
        primary
        style={{ marginTop: 25 }}
        onClick={applyRedemption}
        disabled={!isFormSubmittable}
      >
        {locale.continue}
      </Button>
    </div>
  );
};

export default ManualRedemptionModule;
