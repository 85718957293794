import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getStations } from "apis";
import { useApi } from "hooks";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { getStationsPricing } from "apis/station.api";

const SelectStation = forwardRef(
  (
    {
      placeholder = "",
      disableAllOption,
      // ref,
      isPlbOnboarded,
      isPlcOnboarded,
      labelKey = "name",
      sourcePricing = false,
      isOnboarding,
      ...props
    },
    ref
  ) => {
    const stationParams = {
      isPlbOnboarded,
      isPlcOnboarded,
      isOnboarding,
    };
    const {
      request,
      loading,
      result = { stations: [], count: 0 },
      ...apiObj
    } = useApi({
      api: sourcePricing ? getStationsPricing : getStations,
      params: stationParams,
      handleOwnError: true,
    });

    const fetch = useCallback(
      async (params) => {
        return await request({
          perPage: 10,
          page: params.page || 1,
          ...params,
        });
      },
      [request]
    );

    useImperativeHandle(ref, () => ({
      fetch: () => {
        fetch();
      },
    }));

    return (
      <DropdownPaginated
        ref={ref}
        {...props}
        request={async (params) => {
          return await fetch(params);
        }}
        apiError={apiObj?.error}
        total={result.count}
        loading={loading}
        formatRequest={(result) => {
          const { stations = [] } = result || {};
          if (stations && stations.length > 0) {
            return stations.map((d) => {
              return {
                label: d[labelKey],
                value: d.stationId,
                ...d,
              };
            });
          }
          return [];
        }}
        placeholder={apiObj?.error ? "Failed to load options" : placeholder || "Select"}
        {...(!disableAllOption
          ? {
              allOption: [
                {
                  label: "All",
                  value: null,
                },
              ],
            }
          : [])}
      />
    );
  }
);

export default SelectStation;
