import locale from "localization";

export const accountListColumns = [
  { key: "businessNameId", text: locale.businessNameId, width: "7%" },
  { key: "businessNature", text: locale.natureOfBusiness, width: "12%" },
  { key: "accountType", text: locale.accountType, width: "12%" },
  { key: "plbType", text: locale.plbType, width: "12%" },
  { key: "referenceStation", text: locale.referenceStation, width: "12%" },
  { key: "fleetStations", text: locale.redemptionStations, width: "10%" },
  { key: "pesoBalance", text: locale.pesoBalance, width: "12%" },
  { key: "literBalance", text: locale.literBalance, width: "10%" },
  { key: "inventoryLimit", text: locale.inventoryLimit, width: "10%" },
  { key: "dateOnboarded", text: locale.dateOnboarded, width: "12%" },
  { key: "statusDate", text: locale.statusDate, width: "12%" },
  { key: "actions", text: locale.actions, width: "3%" },
];
