import { formatNumber, capitalize } from "utils";
import _ from "lodash";
import locale from "localization";
import moment from "moment";
import { DateTime } from "enums";

export const fleetAccountForm = (state) => {
  const { data, viewMode, editMode, pricingData, computeMovement, computeContractPrice } = state;
  let addtionalObject = {};

  // const basePrice = gasCategoryValues(data.basePrices, viewMode);
  const discounts = gasCategoryValues(data.discounts, viewMode);

  const estimatedVolume = gasCategoryValues(data.estimatedVolume, viewMode);
  const existingVolume = gasCategoryValues(data.existingVolume, viewMode);
  const currentPricing = gasCategoryValues(data, viewMode);

  if (editMode) {
    addtionalObject = { status: { value: data.status } };
  }

  const basePriceDiesel = pricingData
    ? pricingData[0]?.pumpPrice
    : data.basePrices
    ? data?.basePrices[0].price
    : 0;
  const basePriceGas91 = pricingData
    ? pricingData[1]?.pumpPrice
    : data.basePrices
    ? data?.basePrices[1].price
    : 0;
  const basePriceGas95 = pricingData
    ? pricingData[2]?.pumpPrice
    : data.basePrices
    ? data?.basePrices[2].price
    : 0;
  const basePriceGas97 = pricingData
    ? pricingData[3]?.pumpPrice
    : data.basePrices
    ? data?.basePrices[3].price
    : 0;

  const accuMovementDiesel = pricingData
    ? computeMovement(pricingData[0].latestPumpPrice, basePriceDiesel)
    : 0;
  const accuMovementGas91 = pricingData
    ? computeMovement(pricingData[1].latestPumpPrice, basePriceGas91)
    : 0;
  const accuMovementGas95 = pricingData
    ? computeMovement(pricingData[2].latestPumpPrice, basePriceGas95)
    : 0;
  const accuMovementGas97 = pricingData
    ? computeMovement(pricingData[3].latestPumpPrice, basePriceGas97)
    : 0;

  const basePriceDieselDiscount =
    data.basePrices && data.basePrices[0].discount ? data.basePrices[0].discount : 0;
  const basePriceGas91Discount =
    data.basePrices && data.basePrices[1].discount ? data.basePrices[1].discount : 0;
  const basePriceGas95Discount =
    data.basePrices && data.basePrices[2].discount ? data.basePrices[2].discount : 0;
  const basePriceGas97Discount =
    data.basePrices && data.basePrices[3].discount ? data.basePrices[3].discount : 0;

  const contractPriceDiesel = pricingData
    ? computeContractPrice(basePriceDiesel, accuMovementDiesel, basePriceDieselDiscount)
    : computeContractPrice(basePriceDiesel, 0, basePriceDieselDiscount);

  const contractPriceGas91 = pricingData
    ? computeContractPrice(basePriceGas91, accuMovementGas91, basePriceGas91Discount)
    : computeContractPrice(basePriceGas91, 0, basePriceGas91Discount);
  const contractPriceGas95 = pricingData
    ? computeContractPrice(basePriceGas95, accuMovementGas95, basePriceGas95Discount)
    : computeContractPrice(basePriceGas95, 0, basePriceGas95Discount);
  const contractPriceGas97 = pricingData
    ? computeContractPrice(basePriceGas97, accuMovementGas97, basePriceGas97Discount)
    : computeContractPrice(basePriceGas97, 0, basePriceGas97Discount);

  return {
    accountType: {
      value: data?.accountType,
    },
    businessName: {
      value: data?.businessName,
    },
    businessNature: {
      value: data?.businessNature,
    },
    businessType: {
      value: data?.businessType,
    },
    businessAddress: {
      value: data?.businessAddress,
    },
    province: {
      value: data?.province,
    },
    city: {
      value: data?.city,
    },
    emailAddresses: {
      value: data?.emailAddresses,
    },
    referenceStationId: {
      value: data.referenceStation?.stationId,
      label: data.referenceStation?.name,
    },
    priceAssessmentDate: {
      value: pricingData ? moment(data.priceAssessmentDate).format(DateTime.E) : "",
    },
    dateTimeOnboarded: {
      value: moment(data.dateTimeOnboarded).format(DateTime.E),
    },
    fleetStations: {
      value: data.fleetStations.map((station) => {
        return {
          value: station.stationId,
          label: station.name,
        };
      }),
      isAll: !data.fleetStations.length,
      // label: data.redemptionStationsId?.name,
    },
    fuelCards: {
      value: data.fuelCards,
    },
    depotId: {
      value: data.depot?.depotId,
      label: data.depot?.name,
    },
    shortName: {
      value: data?.shortName,
    },
    acquirerName: {
      value: data?.acquirerName,
    },
    acquirerEmail: {
      value: data?.acquirerEmail,
    },
    introductoryPromoOffer: {
      value: data?.introductoryPromoOffer,
    },
    invoicingSetup: {
      value: data?.invoicingSetup,
    },
    plbType: {
      value: data?.plbType,
    },
    businessTelephoneNumber: {
      value: data?.businessTelephoneNumber,
    },
    businessRepresentativeName: {
      value: data?.businessRepresentativeName,
    },
    representativeMobileNumber: {
      value: data?.representativeMobileNumber,
    },
    representativeEmail: {
      value: data?.representativeEmail,
    },
    baseDieselPrice: {
      value: basePriceDiesel,
      parseNumber: true,
    },
    baseGas91Price: {
      value: basePriceGas91,
      parseNumber: true,
    },
    baseGas95Price: {
      value: basePriceGas95,
      parseNumber: true,
    },
    baseGas97Price: {
      value: basePriceGas97,
      parseNumber: true,
    },
    accuMovementDiesel: {
      value: accuMovementDiesel,
      parseNumber: true,
    },
    accuMovementGas91: {
      value: accuMovementGas91,
      parseNumber: true,
    },
    accuMovementGas95: {
      value: accuMovementGas95,
      parseNumber: true,
    },
    accuMovementGas97: {
      value: accuMovementGas97,
      parseNumber: true,
    },
    contractPriceDiesel: {
      value: contractPriceDiesel,
      parseNumber: true,
    },
    contractPriceGas91: {
      value: contractPriceGas91,
      parseNumber: true,
    },
    contractPriceGas95: {
      value: contractPriceGas95,
      parseNumber: true,
    },
    contractPriceGas97: {
      value: contractPriceGas97,
      parseNumber: true,
    },
    discountDiesel: {
      value: discounts.diesel,
      parseNumber: true,
    },
    discountGas91: {
      value: discounts.gas91,
      parseNumber: true,
    },
    discountGas95: {
      value: discounts.gas95,
      parseNumber: true,
    },
    discountGas97: {
      value: discounts.gas97,
      parseNumber: true,
    },
    estimatedDieselVolume: {
      value: estimatedVolume.diesel,
      parseNumber: true,
    },
    estimatedGas91Volume: {
      value: estimatedVolume.gas91,
      parseNumber: true,
    },
    estimatedGas95Volume: {
      value: estimatedVolume.gas95,
      parseNumber: true,
    },
    estimatedGas97Volume: {
      value: estimatedVolume.gas97,
      parseNumber: true,
    },
    existingDieselVolume: {
      value: existingVolume.diesel,
      parseNumber: true,
    },
    existingGas91Volume: {
      value: existingVolume.gas91,
      parseNumber: true,
    },
    existingGas95Volume: {
      value: existingVolume.gas95,
      parseNumber: true,
    },
    existingGas97Volume: {
      value: existingVolume.gas97,
      parseNumber: true,
    },
    sdumDiesel: {
      value: currentPricing?.diesel?.sdum || 0,
      parseNumber: true,
    },
    sdumGas91: {
      value: currentPricing?.gas91?.sdum || 0,
      parseNumber: true,
    },
    sdumGas95: {
      value: currentPricing?.gas95?.sdum || 0,
      parseNumber: true,
    },
    sdumGas97: {
      value: currentPricing?.gas97?.sdum || 0,
      parseNumber: true,
    },
    freightDiesel: {
      value: currentPricing?.diesel?.freight || 0,
      parseNumber: true,
    },
    freightGas91: {
      value: currentPricing?.gas91?.freight || 0,
      parseNumber: true,
    },
    freightGas95: {
      value: currentPricing?.gas95?.freight || 0,
      parseNumber: true,
    },
    freightGas97: {
      value: currentPricing?.gas97?.freight || 0,
      parseNumber: true,
    },
    ...addtionalObject,
  };
};

const gasCategoryValues = (category, viewMode) => {
  let objAssign = {};
  for (let i in category) {
    // price, discount, estimatedVolume, currentPricing
    if (category[i]?.price) {
      objAssign = {
        ...objAssign,
        [category[i]?.productCode]: viewMode
          ? category[i]?.price
          : formatNumber(category[i]?.price, 2),
      };
    }
    if (typeof category[i]?.discount === "number") {
      objAssign = {
        ...objAssign,
        [category[i]?.productCode]: viewMode
          ? category[i]?.discount
          : formatNumber(category[i]?.discount, 2),
      };
    }
    if (typeof category[i]?.estimatedVolume === "number") {
      objAssign = {
        ...objAssign,
        [category[i]?.productCode]: viewMode
          ? category[i]?.estimatedVolume
          : formatNumber(category[i]?.estimatedVolume),
      };
    }
    if (typeof category[i]?.existingVolume === "number") {
      objAssign = {
        ...objAssign,
        [category[i]?.productCode]: viewMode
          ? category[i]?.existingVolume
          : formatNumber(category[i]?.existingVolume),
      };
    }
    if (i === "currentPricing") {
      let pricing = {};
      for (const pricingIndex in category[i]) {
        const productPricing = category[i][pricingIndex];
        pricing = {
          ...pricing,
          [productPricing?.productCode]: {
            sdum: viewMode ? productPricing?.sdum : formatNumber(productPricing?.sdum),
            freight: viewMode ? productPricing?.freight : formatNumber(productPricing?.freight),
          },
        };
      }
      objAssign = {
        ...objAssign,
        ...pricing,
      };
    }
  }

  return objAssign;
};

let basedConfirmationEditFormObject = {
  businessInformation: {
    status: { label: locale.status, value: "", horizontal: true },
    businessName: { label: locale.businessName, value: "", horizontal: true },
    businessNature: {
      label: locale.natureOfBusiness,
      value: "",
      horizontal: true,
    },
    businessType: {
      label: locale.typesOfBusiness,
      value: "",
      horizontal: true,
    },
    businessAddress: {
      label: locale.businessAddress,
      value: "",
      horizontal: true,
    },
    province: { label: locale.province, value: "", horizontal: true },
    city: { label: locale.city, value: "", horizontal: true },
    referenceStationId: {
      label: locale.referenceStation,
      value: "",
      horizontal: true,
    },
    priceAssessmentDate: {
      value: "",
    },
    fleetStations: {
      label: locale.redemptionStations,
      value: "",
      horizontal: true,
    },
    depotId: {
      label: locale.depot,
      value: "",
      horizontal: true,
    },
    shortName: {
      label: locale.uniqueFuelCodePrefix,
      value: "",
      horizontal: true,
    },
    invoicingSetup: { label: locale.invoicingSetup, value: "", horizontal: true },
    acquirerName: { label: locale.acquirerName, value: "", horizontal: true },
    acquirerEmail: { label: locale.acquirerEmail, value: "", horizontal: true },
  },
  contactInformation: {
    emailAddresses: {
      label: locale.emailForFleetAccount,
      value: [],
      horizontal: true,
      emailAddress: true,
    },
    businessTelephoneNumber: {
      label: locale.businessTelephoneNumber,
      value: "",
      horizontal: true,
    },
    businessRepresentativeName: {
      label: locale.businessRepresentativeName,
      value: "",
      horizontal: true,
    },
    representativeMobileNumber: {
      label: locale.representativeMobileNumber,
      value: "",
      horizontal: true,
    },
    representativeEmail: {
      label: locale.representativeEmail,
      value: "",
      horizontal: true,
    },
  },
  basePriceUponOnboarding: {
    baseDieselPrice: { label: locale.diesel, value: 0, horizontal: true },
    baseGas91Price: { label: locale.gas91, value: 0, horizontal: true },
    baseGas95Price: { label: locale.gas95, value: 0, horizontal: true },
    baseGas97Price: { label: locale.gas97, value: 0, horizontal: true },
  },
  agreedDiscounts: {
    discountDiesel: { label: locale.diesel, value: 0, horizontal: true },
    discountGas91: { label: locale.gas91, value: 0, horizontal: true },
    discountGas95: { label: locale.gas95, value: 0, horizontal: true },
    discountGas97: { label: locale.gas97, value: 0, horizontal: true },
  },
  estimateMonthlyVolume: {
    estimatedDieselVolume: { label: locale.diesel, value: 0, horizontal: true },
    estimatedGas91Volume: { label: locale.gas91, value: 0, horizontal: true },
    estimatedGas95Volume: { label: locale.gas95, value: 0, horizontal: true },
    estimatedGas97Volume: { label: locale.gas97, value: 0, horizontal: true },
  },
  existingVolume: {
    existingDieselVolume: { label: locale.diesel, value: 0, horizontal: true },
    existingGas91Volume: { label: locale.gas91, value: 0, horizontal: true },
    existingGas95Volume: { label: locale.gas95, value: 0, horizontal: true },
    existingGas97Volume: { label: locale.gas97, value: 0, horizontal: true },
  },
  sdum: {
    sdumDiesel: { label: locale.diesel, value: 0, horizontal: true },
    sdumGas91: { label: locale.gas91, value: 0, horizontal: true },
    sdumGas95: { label: locale.gas95, value: 0, horizontal: true },
    sdumGas97: { label: locale.gas97, value: 0, horizontal: true },
  },
  freight: {
    freightDiesel: { label: locale.diesel, value: 0, horizontal: true },
    freightGas91: { label: locale.gas91, value: 0, horizontal: true },
    freightGas95: { label: locale.gas95, value: 0, horizontal: true },
    freightGas97: { label: locale.gas97, value: 0, horizontal: true },
  },
};

export const fleetEditFormData = (updatingObject, customField) => {
  let reformedObject = _.cloneDeep(basedConfirmationEditFormObject);
  for (const [parentKey, parentValue] of Object.entries(basedConfirmationEditFormObject)) {
    const filteredAvailableFields = Object.keys(updatingObject).filter((key) => key in parentValue);

    for (const element of Object.keys(parentValue)) {
      if (!filteredAvailableFields.includes(element)) {
        delete reformedObject[parentKey][element];
      }
    }

    if (filteredAvailableFields.length > 0) {
      for (const objectElement of filteredAvailableFields) {
        let value = updatingObject[objectElement];
        if (objectElement === "status") {
          value = capitalize(value);
        } else if (objectElement === "emailAddresses") {
          if (
            customField.emailAddresses.length > 0 ||
            customField.removedEmailAddresses.length > 0
          ) {
            const removedItems = customField.removedEmailAddresses.map((o) => {
              delete o.status;
              return Object.assign(o, { removed: true });
            });

            value = [...customField.emailAddresses, ...removedItems];
          } else {
            delete reformedObject[parentKey][objectElement];
            continue;
          }
        } else if (objectElement === "referenceStationId" || objectElement === "depotId") {
          value = customField.stationDepotName?.[objectElement];
        }
        reformedObject = {
          ...reformedObject,
          [parentKey]: {
            ...reformedObject[parentKey],
            [objectElement]: {
              ...reformedObject[parentKey][objectElement],
              value,
            },
          },
        };
      }
    } else {
      delete reformedObject[parentKey];
    }
  }
  return reformedObject;
};
